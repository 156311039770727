<template>
  <v-data-table
    :key="value.key"
    v-model="value.selected"
    class="v-sheet--outlined w-100"
    disable-sort
    hide-default-footer
    :headers="value.headers"
    :items="value.items"
    :item-key="value.itemKey"
  >
    <template v-slot:item.name="{ item }"> {{ item.firstName }} {{ item.lastName }} </template>
    <template v-slot:item.comminications="{ item }"> {{ getComminications(item) }} </template>

    <template v-slot:item.isVerified="{ item }">
      <v-checkbox v-if="item.isVerified" v-model="item.isVerified" class="checkbox d-flex justify-center" disabled />

      <vx-btn v-else text underline color="primary" :loading="item._loading" @click="$emit('resend', item)">
        {{ item.email ? 'Resend' : 'Verify' }}
      </vx-btn>
    </template>

    <template v-slot:item.actions="{ item }">
      <vx-btn icon tooltip="Edit" @click="$emit('edit', item._index)">
        <v-icon>mdi-pencil</v-icon>
      </vx-btn>

      <vx-btn :disabled="item.isPrimary" icon tooltip="Delete" @click="$emit('remove', item._index)">
        <v-icon>mdi-delete</v-icon>
      </vx-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'DataTable',
  props: {
    value: { type: Object, required: true },
  },
  methods: {
    getComminications(item) {
      if (item.notification.company && item.notification.system) {
        return 'Company updates & System notifications';
      }

      if (item.notification.company) {
        return 'Company updates only';
      }

      if (item.notification.system) {
        return 'System notifications only';
      }
    },
  },
};
</script>
