<template>
  <v-form ref="form" lazy-validation>
    <v-card>
      <v-card-title class="card-title-actions">
        <v-spacer />
        <vx-btn width="100" outlined color="light" class="ml-2" @click="$previous()">Cancel</vx-btn>
        <vx-btn width="100" outlined color="light" class="ml-2" :loading="loading" @click="$validate(save)">
          Save
        </vx-btn>
        <vx-btn outlined color="light" class="ml-2" :loading="loading" @click="$validate(save, true)">
          Save & close
        </vx-btn>
      </v-card-title>

      <v-card-text class="pt-4">
        <v-tabs v-model="tab" :show-arrows="$vuetify.breakpoint.xs">
          <v-tab>Basic</v-tab>
          <v-tab>Communication</v-tab>
          <v-tab>Customer support </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Basic :user="user" />
          </v-tab-item>

          <v-tab-item>
            <Communication :user="user" />
          </v-tab-item>

          <v-tab-item>
            <SocialMedia :user="user" />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import SocialMedia from './social-media';
import Basic from './account';
import { UserService } from '@tutti/services';
import { User } from '@tutti/models';
import Communication from './communications/Communications';

export default {
  components: {
    Basic,
    SocialMedia,
    Communication,
  },

  data: () => ({
    tab: 0,
    user: {},
    loading: false,
  }),

  created() {
    this.tab = this.$route?.params?.tab || 0;
    this.getUser();
  },

  methods: {
    async getUser() {
      const response = await UserService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.user = new User(response.data);
        this.$setTitle(this.user.displayName);
      }
    },

    async save(previous) {
      this.loading = true;
      const user = this.user.get();
      delete user.stripe;
      delete user.socketId;
      delete user.ryft;
      delete user.dismissReminder;

      const response = await UserService.save(user._id, user);
      if (response && previous) {
        this.$previous();
      }
      this.loading = false;
    },
  },
};
</script>
