<template>
  <v-dialog v-model="dialog" width="400">
    <v-form ref="form" lazy-validation>
      <v-card>
        <vx-card-title class="py-4">Verify your phone number</vx-card-title>

        <v-card-subtitle class="py-4">
          Please enter the verification code we sent to your phone ({{ secondaryPhone.phone }}).
        </v-card-subtitle>

        <v-card-text>
          <v-row>
            <v-col cols="12" sm="8" md="8">
              <vx-input
                v-model="verificationCode"
                v-mask="'######'"
                type="text"
                label="Verification Code"
                required
                @keyup.native.enter="verifyOTP()"
              />
            </v-col>

            <v-col cols="4" sm="4" md="4">
              <vx-btn
                block
                outlined
                :disabled="disabled"
                :loading="loading.resend"
                color="primary"
                @click="requestOTP()"
              >
                Resend
              </vx-btn>
            </v-col>
          </v-row>
          <span v-if="disabled">
            You can resend OTP in <b>{{ countDown }}</b>
          </span>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-row>
            <v-col cols="5" sm="5" md="5">
              <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
            </v-col>

            <v-col cols="7" sm="7" md="7">
              <vx-btn block color="primary" :loading="loading.verify" @click="verifyOTP()">Verify</vx-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { AuthService } from '@tutti/services';

export default {
  name: 'SettingsAddEmail',
  props: {
    value: { type: Boolean, required: true },
    user: { type: Object, required: true },
    secondaryPhone: { type: Object, required: true },
  },
  data() {
    return {
      loading: {
        resend: false,
      },
      disabled: false,
      dialog: this.value,
      verificationCode: null,
      countDown: 60,
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  methods: {
    countDownTimer() {
      const timer = setInterval(() => {
        this.countDown -= 1;
        if (this.countDown <= 0) {
          this.countDown = 60;
          this.disabled = false;
          clearInterval(timer);
        }
      }, 1000);
    },
    async requestOTP() {
      this.loading.resend = true;

      const response = await AuthService.requestOTP(this.secondaryPhone.phone);
      if (response) {
        this.disabled = true;
        this.countDownTimer();
      }

      this.loading.resend = false;
    },
    async verifyOTP() {
      this.loading.verify = true;

      const response = await AuthService.verifyOTP({
        phone: this.secondaryPhone.phone,
        OTP: this.verificationCode,
        userId: this.user._id,
      });

      if (response) {
        this.dialog = false;
        this.$emit('change');
      }

      this.loading.verify = false;
    },
  },
};
</script>
