var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "activeCampaign",
      "type": "text",
      "name": "activeCampaign",
      "label": "Active Campaign"
    },
    model: {
      value: _vm.user.support.activeCampaign.id,
      callback: function callback($$v) {
        _vm.$set(_vm.user.support.activeCampaign, "id", $$v);
      },
      expression: "user.support.activeCampaign.id"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "hubspot",
      "type": "text",
      "name": "hubspot",
      "label": "Hubspot"
    },
    model: {
      value: _vm.user.support.hubspot.id,
      callback: function callback($$v) {
        _vm.$set(_vm.user.support.hubspot, "id", $$v);
      },
      expression: "user.support.hubspot.id"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "intercom",
      "type": "text",
      "name": "intercom",
      "label": "Intercom"
    },
    model: {
      value: _vm.user.support.intercom.id,
      callback: function callback($$v) {
        _vm.$set(_vm.user.support.intercom, "id", $$v);
      },
      expression: "user.support.intercom.id"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "voucherify",
      "type": "text",
      "name": "voucherify",
      "label": "Voucherify"
    },
    model: {
      value: _vm.user.support.voucherify.id,
      callback: function callback($$v) {
        _vm.$set(_vm.user.support.voucherify, "id", $$v);
      },
      expression: "user.support.voucherify.id"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "stripe",
      "type": "text",
      "name": "stripe",
      "label": "Stripe"
    },
    model: {
      value: _vm.user.support.stripe.customerId,
      callback: function callback($$v) {
        _vm.$set(_vm.user.support.stripe, "customerId", $$v);
      },
      expression: "user.support.stripe.customerId"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }