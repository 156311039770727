var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "firstName",
      "type": "text",
      "name": "firstName",
      "label": "First name",
      "required": ""
    },
    model: {
      value: _vm.user.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "firstName", $$v);
      },
      expression: "user.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "lastName",
      "type": "text",
      "name": "lastName",
      "label": "Last name",
      "required": ""
    },
    model: {
      value: _vm.user.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "lastName", $$v);
      },
      expression: "user.lastName"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "email",
      "type": "email",
      "name": "email",
      "label": "Email",
      "required": ""
    },
    model: {
      value: _vm.user.email,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "email", $$v);
      },
      expression: "user.email"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "username",
      "type": "text",
      "name": "username",
      "label": "Username",
      "required": ""
    },
    model: {
      value: _vm.user.username,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "username", $$v);
      },
      expression: "user.username"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "userType",
      "type": "select",
      "items": _vm.USER_TYPES_ALL,
      "name": "userType",
      "label": "Actual type",
      "required": ""
    },
    model: {
      value: _vm.user.userType,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "userType", $$v);
      },
      expression: "user.userType"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "id": "intentionUserType",
      "type": "select",
      "items": _vm.USER_TYPES_ALL,
      "name": "intentionUserType",
      "label": "Intention type",
      "required": ""
    },
    model: {
      value: _vm.user.intentionUserType,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "intentionUserType", $$v);
      },
      expression: "user.intentionUserType"
    }
  })], 1)], 1), _vm.$hasPermission('role') ? _c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "select",
      "label": "Role",
      "item-text": "name",
      "item-value": "_id",
      "clearable": false,
      "items": _vm.roles,
      "required": ""
    },
    model: {
      value: _vm.user.role,
      callback: function callback($$v) {
        _vm.$set(_vm.user, "role", $$v);
      },
      expression: "user.role"
    }
  })], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }