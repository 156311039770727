var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('section', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-h6 my-auto"
  }, [_vm._v("Emails")]), _c('div', {
    staticClass: "ml-auto my-2"
  }, [_c('vx-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addEmail();
      }
    }
  }, [_vm._v("Add email")])], 1)]), _c('DataTable', {
    key: _vm.key,
    on: {
      "edit": _vm.addEmail,
      "resend": _vm.resendEmail,
      "remove": _vm.removeEmail
    },
    model: {
      value: _vm.dataTable.email,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "email", $$v);
      },
      expression: "dataTable.email"
    }
  })], 1), _c('br'), _c('section', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "text-h6 my-auto"
  }, [_vm._v("Phone (SMS)")]), _vm.user.secondaryPhones && _vm.user.secondaryPhones.length < 2 ? _c('div', {
    staticClass: "ml-auto my-2"
  }, [_c('vx-btn', {
    attrs: {
      "outlined": "",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.addPhone();
      }
    }
  }, [_vm._v("Add phone")])], 1) : _vm._e()]), _c('DataTable', {
    key: _vm.key,
    on: {
      "edit": _vm.addPhone,
      "resend": _vm.resendPhone,
      "remove": _vm.removePhone
    },
    model: {
      value: _vm.dataTable.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.dataTable, "phone", $$v);
      },
      expression: "dataTable.phone"
    }
  })], 1), _vm.modal.email ? _c('AddEditEmail', {
    attrs: {
      "user": _vm.user,
      "index": _vm.index.email
    },
    on: {
      "change": function change($event) {
        _vm.dataTable.email.key++;
      }
    },
    model: {
      value: _vm.modal.email,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "email", $$v);
      },
      expression: "modal.email"
    }
  }) : _vm._e(), _vm.modal.phone ? _c('AddEditPhone', {
    attrs: {
      "user": _vm.user,
      "index": _vm.index.phone
    },
    on: {
      "change": _vm.onChangePhone
    },
    model: {
      value: _vm.modal.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "phone", $$v);
      },
      expression: "modal.phone"
    }
  }) : _vm._e(), _vm.modal.verifyPhone ? _c('VerifyPhone', {
    attrs: {
      "secondary-phone": _vm.secondaryPhone,
      "user": _vm.user
    },
    on: {
      "change": function change($event) {
        return _vm.getUser();
      }
    },
    model: {
      value: _vm.modal.verifyPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.modal, "verifyPhone", $$v);
      },
      expression: "modal.verifyPhone"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }