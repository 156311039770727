<template>
  <div>
    <section>
      <div class="d-flex">
        <div class="text-h6 my-auto">Emails</div>
        <div class="ml-auto my-2">
          <vx-btn outlined color="primary" @click="addEmail()">Add email</vx-btn>
        </div>
      </div>

      <DataTable :key="key" v-model="dataTable.email" @edit="addEmail" @resend="resendEmail" @remove="removeEmail" />
    </section>

    <br />

    <section>
      <div class="d-flex">
        <div class="text-h6 my-auto">Phone (SMS)</div>
        <div v-if="user.secondaryPhones && user.secondaryPhones.length < 2" class="ml-auto my-2">
          <vx-btn outlined color="primary" @click="addPhone()">Add phone</vx-btn>
        </div>
      </div>

      <DataTable :key="key" v-model="dataTable.phone" @edit="addPhone" @resend="resendPhone" @remove="removePhone" />
    </section>

    <AddEditEmail
      v-if="modal.email"
      v-model="modal.email"
      :user="user"
      :index="index.email"
      @change="dataTable.email.key++"
    />

    <AddEditPhone v-if="modal.phone" v-model="modal.phone" :user="user" :index="index.phone" @change="onChangePhone" />

    <VerifyPhone
      v-if="modal.verifyPhone"
      v-model="modal.verifyPhone"
      :secondary-phone="secondaryPhone"
      :user="user"
      @change="getUser()"
    />
  </div>
</template>

<script>
import { User } from '@tutti/models';
import { AuthService, UserService } from '@tutti/services';

import DataTable from './CommunicationsTable';
import VerifyPhone from './VerifyPhone';
import AddEditEmail from './AddEditEmail';
import AddEditPhone from './AddEditPhone';

export default {
  name: 'Communications',
  components: {
    DataTable,
    VerifyPhone,
    AddEditEmail,
    AddEditPhone,
  },
  data() {
    return {
      key: 0,
      user: {},
      loading: false,
      modal: {
        email: false,
        phone: false,
        verifyPhone: false,
      },
      index: {
        email: null,
        phone: null,
      },
      dataTable: {
        email: {
          key: 0,
          itemKey: '_id',
          selected: [],
          items: [],
          headers: [
            { text: 'Name', value: 'name', width: '20%', align: 'center' },
            { text: 'Email', value: 'email', width: '20%', align: 'center' },
            { text: 'Communications', value: 'comminications', width: '30%', align: 'center' },
            { text: 'Verified?', value: 'isVerified', width: '10%', align: 'center' },
            { text: 'Actions', value: 'actions', width: '20%', align: 'center' },
          ],
        },
        phone: {
          key: 0,
          itemKey: '_id',
          selected: [],
          items: [],
          headers: [
            { text: 'Name', value: 'name', width: '20%', align: 'center' },
            { text: 'Phone', value: 'phone', width: '20%', align: 'center' },
            { text: 'Communications', value: 'comminications', width: '30%', align: 'center' },
            { text: 'Verified?', value: 'isVerified', width: '10%', align: 'center' },
            { text: 'Actions', value: 'actions', width: '20%', align: 'center' },
          ],
        },
      },
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    async getUser() {
      const response = await UserService.getByIdAdmin(this.$route.params.id);
      if (response) {
        this.user = new User(response.data);

        this.dataTable.email.items = this.user.secondaryEmails;
        this.dataTable.phone.items = this.user.secondaryPhones;
        this.key++;
      }
    },
    addEmail(index) {
      this.index.email = index;
      this.modal.email = true;
    },
    addPhone(index) {
      this.index.phone = index;
      this.modal.phone = true;
    },
    async removeEmail(index) {
      const confirm = await this.$confirm('DELETE_EMAIL');
      if (confirm) {
        this.user.removeEmail(index);
        this.save('secondaryEmails');
      }
    },
    async removePhone(index) {
      const confirm = await this.$confirm('DELETE_PHONE');
      if (confirm) {
        this.user.removePhone(index);
        this.save('secondaryPhones');
      }
    },
    onChangePhone(secondaryPhone) {
      if (!secondaryPhone.isVerified) {
        this.secondaryPhone = secondaryPhone;
        this.modal.verifyPhone = true;
      }
      this.dataTable.phone.key++;
    },
    async resendEmail(ietm) {
      if (ietm.email) {
        ietm._loading = true;
        await AuthService.resendVerifySecondaryEmail({
          email: ietm.email,
        });
        ietm._loading = false;
      }
    },
    async resendPhone(ietm) {
      ietm._loading = true;
      const response = await AuthService.requestOTP(ietm.phone, true);
      if (response) {
        this.secondaryPhone = ietm;
        this.modal.verifyPhone = true;
      }
      ietm._loading = false;
    },
    async save(key) {
      this.loading = true;

      let parmas = {};
      if (key) {
        const user = this.user.get();
        parmas[key] = user[key];
      } else {
        parmas = this.user.getNotificationInfo();
      }

      const response = await UserService.save(this.user._id, parmas);

      if (response) {
        this.setUser(response.data.user);
        this.getUser();
      }

      this.loading = false;
    },
  },
};
</script>
