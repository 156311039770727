<template>
  <v-dialog v-model="dialog" width="500">
    <v-form ref="form" lazy-validation>
      <v-card>
        <vx-card-title>{{ index || index == 0 ? 'Edit' : 'Add' }} email</vx-card-title>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="secondaryEmail.firstName" type="text" label="First Name" required />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="secondaryEmail.lastName" type="text" label="Last Name" required />
            </v-col>

            <v-col cols="12" class="py-0">
              <vx-input
                v-model="secondaryEmail.email"
                type="email"
                :disabled="secondaryEmail.isPrimary"
                label="Email"
                required
                :unique="{ key: 'email', index: index, items: user.secondaryEmails }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>What notifications do you want to receive?</div>

              <v-checkbox v-model="secondaryEmail.notification.company" dense label="Company updates" />
              <v-checkbox
                v-model="secondaryEmail.notification.system"
                dense
                label="System notifications"
                :disabled="isDisabled()"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="primary" :loading="loading" @click="$validate(submit)"> Save </vx-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
import { UserService } from '@tutti/services';

export default {
  name: 'SettingsAddEmail',
  props: {
    value: { type: Boolean, required: true },
    user: { type: Object, required: true },
    index: { type: Number, required: false },
  },
  data() {
    return {
      loading: false,
      dialog: this.value,
      secondaryEmail: _.cloneDeep(this.user.secondaryEmails[this.index]),
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  created() {
    if (!this.secondaryEmail) {
      this.secondaryEmail = _.cloneDeep(this.user._secondaryEmail);
      this.secondaryEmail._index = this.user.secondaryEmails.length;
    }

    this.secondaryEmail.firstName = this.secondaryEmail.firstName || this.user.firstName;
    this.secondaryEmail.lastName = this.secondaryEmail.lastName || this.user.lastName;
  },
  methods: {
    isDisabled() {
      return !this.user.secondaryEmails.some((email, index) => index != this.index && email.notification.system);
    },
    submit() {
      if (this.index || this.index == 0) {
        const secondaryEmail = this.user.secondaryEmails[this.index];

        if (secondaryEmail.email != this.secondaryEmail.email) {
          this.secondaryEmail.isNew = true;
          this.secondaryEmail.isVerified = false;
        }

        this.user.secondaryEmails[this.index] = this.secondaryEmail;
      } else {
        this.user.addEmail(this.secondaryEmail);
      }

      this.save();
    },
    async save() {
      this.loading = true;

      const user = this.user.get();
      const response = await UserService.save(this.user._id, { secondaryEmails: user.secondaryEmails });

      if (response) {
        this.dialog = false;
        this.$emit('change', this.secondaryEmail);
      }

      this.loading = false;
    },
  },
};
</script>
