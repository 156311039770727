<template>
  <v-dialog v-model="dialog" width="500">
    <v-form ref="form" lazy-validation>
      <v-card>
        <vx-card-title>{{ index || index == 0 ? 'Edit' : 'Add' }} phone</vx-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="secondaryPhone.firstName" type="text" label="First Name" required />
            </v-col>

            <v-col cols="12" sm="6" class="py-0">
              <vx-input v-model="secondaryPhone.lastName" type="text" label="Last Name" required />
            </v-col>

            <v-col cols="12" class="py-0">
              <vx-input
                type="tel"
                label="Phone"
                :value="secondaryPhone.phone"
                :phone.sync="secondaryPhone.phone"
                required
                :unique="{ key: 'phone', index: index, items: user.secondaryPhones }"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div>What notifications do you want to receive?</div>

              <!-- <v-checkbox label="Company updates" v-model="secondaryPhone.notification.company" /> -->
              <v-checkbox v-model="secondaryPhone.notification.system" dense label="System notifications" />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-row>
            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="tertiary" @click="dialog = false">Cancel</vx-btn>
            </v-col>

            <v-col cols="6" sm="6" md="6">
              <vx-btn block color="primary" :loading="loading" @click="$validate(submit)"> Save </vx-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { AuthService, UserService } from '@tutti/services';
import _ from 'lodash';

export default {
  name: 'SettingsAddEmail',
  props: {
    value: { type: Boolean, required: true },
    user: { type: Object, required: true },
    index: { type: Number, required: false },
  },
  data() {
    return {
      loading: false,
      dialog: this.value,
      secondaryPhone: _.cloneDeep(this.user.secondaryPhones[this.index]),
    };
  },
  watch: {
    value(newValue) {
      this.dialog = newValue;
    },
    dialog(newValue) {
      this.$emit('input', newValue);
    },
  },
  created() {
    if (!this.secondaryPhone) {
      this.secondaryPhone = _.cloneDeep(this.user._secondaryPhone);
      this.secondaryPhone._index = this.user.secondaryPhones.length;
    }

    this.secondaryPhone.firstName = this.secondaryPhone.firstName || this.user.firstName;
    this.secondaryPhone.lastName = this.secondaryPhone.lastName || this.user.lastName;
  },
  methods: {
    async submit() {
      if (this.index || this.index == 0) {
        const secondaryPhone = this.user.secondaryPhones[this.index];

        if (secondaryPhone.phone != this.secondaryPhone.phone) {
          this.secondaryPhone.isNew = true;
          this.secondaryPhone.isVerified = false;
          await AuthService.requestOTP(this.secondaryPhone.phone);
        }

        this.user.secondaryPhones[this.index] = this.secondaryPhone;
      } else {
        await AuthService.requestOTP(this.secondaryPhone.phone);
        this.user.addPhone(this.secondaryPhone);
      }

      this.save();
    },
    async save() {
      this.loading = true;

      const user = this.user.get();
      const response = await UserService.save(this.user._id, { secondaryPhones: user.secondaryPhones });

      if (response) {
        this.dialog = false;
        this.$emit('change', this.secondaryPhone);
      }

      this.loading = false;
    },
  },
};
</script>
