var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('vx-card-title', [_vm._v(_vm._s(_vm.index || _vm.index == 0 ? 'Edit' : 'Add') + " phone")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "label": "First Name",
      "required": ""
    },
    model: {
      value: _vm.secondaryPhone.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.secondaryPhone, "firstName", $$v);
      },
      expression: "secondaryPhone.firstName"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "text",
      "label": "Last Name",
      "required": ""
    },
    model: {
      value: _vm.secondaryPhone.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.secondaryPhone, "lastName", $$v);
      },
      expression: "secondaryPhone.lastName"
    }
  })], 1), _c('v-col', {
    staticClass: "py-0",
    attrs: {
      "cols": "12"
    }
  }, [_c('vx-input', {
    attrs: {
      "type": "tel",
      "label": "Phone",
      "value": _vm.secondaryPhone.phone,
      "phone": _vm.secondaryPhone.phone,
      "required": "",
      "unique": {
        key: 'phone',
        index: _vm.index,
        items: _vm.user.secondaryPhones
      }
    },
    on: {
      "update:phone": function updatePhone($event) {
        return _vm.$set(_vm.secondaryPhone, "phone", $event);
      }
    }
  })], 1)], 1), _c('v-row', [_c('v-col', [_c('div', [_vm._v("What notifications do you want to receive?")]), _c('v-checkbox', {
    attrs: {
      "dense": "",
      "label": "System notifications"
    },
    model: {
      value: _vm.secondaryPhone.notification.system,
      callback: function callback($$v) {
        _vm.$set(_vm.secondaryPhone.notification, "system", $$v);
      },
      expression: "secondaryPhone.notification.system"
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "tertiary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "6",
      "md": "6"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.loading
    },
    on: {
      "click": function click($event) {
        return _vm.$validate(_vm.submit);
      }
    }
  }, [_vm._v(" Save ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }