var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": "400"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-form', {
    ref: "form",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-card', [_c('vx-card-title', {
    staticClass: "py-4"
  }, [_vm._v("Verify your phone number")]), _c('v-card-subtitle', {
    staticClass: "py-4"
  }, [_vm._v(" Please enter the verification code we sent to your phone (" + _vm._s(_vm.secondaryPhone.phone) + "). ")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "8",
      "md": "8"
    }
  }, [_c('vx-input', {
    directives: [{
      name: "mask",
      rawName: "v-mask",
      value: '######',
      expression: "'######'"
    }],
    attrs: {
      "type": "text",
      "label": "Verification Code",
      "required": ""
    },
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.verifyOTP();
      }
    },
    model: {
      value: _vm.verificationCode,
      callback: function callback($$v) {
        _vm.verificationCode = $$v;
      },
      expression: "verificationCode"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "4",
      "md": "4"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "outlined": "",
      "disabled": _vm.disabled,
      "loading": _vm.loading.resend,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.requestOTP();
      }
    }
  }, [_vm._v(" Resend ")])], 1)], 1), _vm.disabled ? _c('span', [_vm._v(" You can resend OTP in "), _c('b', [_vm._v(_vm._s(_vm.countDown))])]) : _vm._e()], 1), _c('v-card-actions', {
    staticClass: "pt-0"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "5",
      "sm": "5",
      "md": "5"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "tertiary"
    },
    on: {
      "click": function click($event) {
        _vm.dialog = false;
      }
    }
  }, [_vm._v("Cancel")])], 1), _c('v-col', {
    attrs: {
      "cols": "7",
      "sm": "7",
      "md": "7"
    }
  }, [_c('vx-btn', {
    attrs: {
      "block": "",
      "color": "primary",
      "loading": _vm.loading.verify
    },
    on: {
      "click": function click($event) {
        return _vm.verifyOTP();
      }
    }
  }, [_vm._v("Verify")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }