<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="firstName" v-model="user.firstName" type="text" name="firstName" label="First name" required />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="lastName" v-model="user.lastName" type="text" name="lastName" label="Last name" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="email" v-model="user.email" type="email" name="email" label="Email" required />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="username" v-model="user.username" type="text" name="username" label="Username" required />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            id="userType"
            v-model="user.userType"
            type="select"
            :items="USER_TYPES_ALL"
            name="userType"
            label="Actual type"
            required
          />
        </v-col>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            id="intentionUserType"
            v-model="user.intentionUserType"
            type="select"
            :items="USER_TYPES_ALL"
            name="intentionUserType"
            label="Intention type"
            required
          />
        </v-col>
      </v-row>
      <v-row v-if="$hasPermission('role')">
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            v-model="user.role"
            type="select"
            label="Role"
            item-text="name"
            item-value="_id"
            :clearable="false"
            :items="roles"
            required
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { USER_TYPES_ALL, ROLE_TYPE } from '@tutti/constants';
import { RoleService } from '@tutti/services';

export default {
  props: {
    user: { type: Object, required: true },
  },
  data() {
    return {
      USER_TYPES_ALL,
      roles: [],
    };
  },
  async created() {
    await this.getUserRoles();
  },
  methods: {
    async getUserRoles() {
      const response = await RoleService.getAll({ filter: { type: ROLE_TYPE.USER } });
      if (response) {
        this.roles = response.data.data;
        this.roles.map(role => (role.name = `${role.name} ${role.hasAdminPrivilege ? '(Has Admin Access)' : ''}`));
      }
    },
  },
};
</script>
