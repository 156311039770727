<template>
  <v-card outlined>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            id="activeCampaign"
            v-model="user.support.activeCampaign.id"
            type="text"
            name="activeCampaign"
            label="Active Campaign"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="hubspot" v-model="user.support.hubspot.id" type="text" name="hubspot" label="Hubspot" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="intercom" v-model="user.support.intercom.id" type="text" name="intercom" label="Intercom" />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input
            id="voucherify"
            v-model="user.support.voucherify.id"
            type="text"
            name="voucherify"
            label="Voucherify"
          />
        </v-col>

        <v-col cols="12" sm="6" class="py-0">
          <vx-input id="stripe" v-model="user.support.stripe.customerId" type="text" name="stripe" label="Stripe" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    user: { type: Object, required: true },
  },

  created() {
    this.user.support = this.user.support || {};
    this.user.support.activeCampaign = this.user.support.activeCampaign || {};
    this.user.support.hubspot = this.user.support.hubspot || {};
    this.user.support.intercom = this.user.support.intercom || {};
    this.user.support.voucherify = this.user.support.voucherify || {};
    this.user.support.stripe = this.user.support.stripe || {};
  },
};
</script>
