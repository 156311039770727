var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-data-table', {
    key: _vm.value.key,
    staticClass: "v-sheet--outlined w-100",
    attrs: {
      "disable-sort": "",
      "hide-default-footer": "",
      "headers": _vm.value.headers,
      "items": _vm.value.items,
      "item-key": _vm.value.itemKey
    },
    scopedSlots: _vm._u([{
      key: "item.name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" " + _vm._s(item.firstName) + " " + _vm._s(item.lastName) + " ")];
      }
    }, {
      key: "item.comminications",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_vm._v(" " + _vm._s(_vm.getComminications(item)) + " ")];
      }
    }, {
      key: "item.isVerified",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.isVerified ? _c('v-checkbox', {
          staticClass: "checkbox d-flex justify-center",
          attrs: {
            "disabled": ""
          },
          model: {
            value: item.isVerified,
            callback: function callback($$v) {
              _vm.$set(item, "isVerified", $$v);
            },
            expression: "item.isVerified"
          }
        }) : _c('vx-btn', {
          attrs: {
            "text": "",
            "underline": "",
            "color": "primary",
            "loading": item._loading
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('resend', item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.email ? 'Resend' : 'Verify') + " ")])];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('vx-btn', {
          attrs: {
            "icon": "",
            "tooltip": "Edit"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('edit', item._index);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-pencil")])], 1), _c('vx-btn', {
          attrs: {
            "disabled": item.isPrimary,
            "icon": "",
            "tooltip": "Delete"
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('remove', item._index);
            }
          }
        }, [_c('v-icon', [_vm._v("mdi-delete")])], 1)];
      }
    }]),
    model: {
      value: _vm.value.selected,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "selected", $$v);
      },
      expression: "value.selected"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }